
.selectize-control {
  .selectize-input {
    border-radius: 4px;
    border: solid 1px #cccccc;
    background-color: #fafafa;
    box-shadow: none;
    background-image: none;

    input {
      width: auto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #828485;
    }
  }

}

.selectize-dropdown {
  .selectize-dropdown-content{
    padding-top: 4px;
    padding-bottom: 4px;
    .option {
      cursor: pointer;
      font-family: CiscoSans, Arial, sans-serif;
      padding: 8px 12px;
      font-size: 14px;
      color: #000;

      &.active{
        background-color: rgb(222,235,255);
      }
    }
  }
}
