.xmart {
  table.olh_note {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.2em;
    border-left: 4px solid #64bbe3;
    background: #f3f6f9;
    padding: 10.5px 21px;
    margin: 21px 0;

    hr{
      display: none;
    }

    td:first-child {
      display: none;
    }

    td p{
      margin: 0;
    }
  }

  img{
    width: auto;
  }
}
