.pubhub-sandbox {
  padding-top: 2.5rem;
  padding-bottom: 5rem;

  &-title {
    text-align: center;

    padding-bottom: calculateRem(10px);
    font-size: 2.375rem;
    color: $gray-dark;
    font-weight: $font-weight-extra-light;
  }

  &-lead {
    font-size: 1.125rem;
    line-height: 1.39;
    text-align: center;
    color: $gray-dark;


    .pubhub-info-card-link{
      margin-left: calculateRem(10px);
      font-weight: $font-weight-bold;


      &:after{
        content: ' ';
        display: inline-block;
        width: 0;
        height: 0;
        border: solid 0.375rem;
        border-color: transparent transparent transparent #049fd9;
        margin-left: calculateRem(10px)
      }


      &:hover:after{
        border-color: transparent transparent transparent $link-hover-color;
      }

    }

  }

  .pubhub-sandbox-info-card {

  }

}
