.pubhub-forum{
    background-color: #F2F2F2;

    padding: 40px 0px 60px 0px;

    .pubhub-forum-title{
        font-size: 3rem;
        text-align: center;
        font-weight: 200;
        line-height: 1.2;
        color: #4a4a4a;
        padding: 16px;
    }

    .pubhub-forum-catalog-box{
        display: block;
        overflow: hidden;
        margin-top: 1.6em;
        text-decoration: none;

        &::before{
            content: '';
            display: block;
            height: 8px;
            background-color: #ccc;
            border-radius: 4px 4px 0 0;
        }

        &.pubhub-forum-tech-network, &.pubhub-forum-tech-networking{
            &::before{
                background-color: #0084ae;
            }
        }

        &.pubhub-forum-tech-collab, &.pubhub-forum-tech-collaboration{
            &::before{
                background-color: #00b5ad;
            }
        }

        &.pubhub-forum-tech-iot{
            &::before{
                background-color: #68a94a;
            }
        }

        .pubhub-forum-catalog{
            border: solid 1px #ddd;
            border-top: none;
            border-radius: 0 0 4px 4px;
            padding: 0.8em 1em;

            &-title{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px;
                line-height: 1.6;
                font-weight: 500;
                color: #4a4a4a;
            }

            &-description{
                font-size: 12px;
                line-height: 1.6;
                height: 12px * 1.6 * 4;
                overflow: hidden;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                color:lighten(#4a4a4a, 25%);
            }
        }
    }

    .pubhub-forum-actions{
        .btn{
            display: inline-block;
            margin: 40px 20px 0 0;

            .pubhub-btn-icon{
                display: inline-block;
                width: 18px;
                height: 18px;
                background-image: url('./img/spark-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                margin-right: 10px;
                transform: scale(1.2);
            }
        }

        .btn-primary-outline{
            border: solid 1px #049fd9;
            background-color: #fff;

            &:hover{
                border-color: #037aa7;
                background-color: transparent;
            }
        }
    }
}