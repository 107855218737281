
#pubhub-container[data-platform="sm"] .doc-header {
  display: block;
}

#pubhub-container .doc-header {
  padding-bottom: 1px;
  // height: 61px;
  display: none;
  background-color: #F7F7F7;

  // &--fixed .doc-header__fixed {
  //   position: fixed;
  // }

  // &__fixed {
  //   position: relative;
  //   width: 100%;
  //   z-index: 96;
  //   top: 0;
  // }

  &__top {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #FFF;
    height: 60px;
    padding: 20px 16px;
    position: relative;
  }

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__version-list {
    height: 0;
    transition: height ease .3s 0s;
    overflow: hidden;
    position: absolute;
    top: 44px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    max-width: 100%;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background-color: #F7F7F7;
    z-index: 1;
  }
  &__version-item {
    background-color: #FFF;
    margin-top: 1px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  &__version-link {
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 10px 12px;
  }

  &__icon {
    transition: color ease .3s 0s;
    &--search {
      color: #707273;
    }
    &--close {
      color: #D0D0D0;
    }
    &--active {
      color: #049fd9;
    }
  }

  &__search {
    transition: left ease .3s 0s;
    overflow: hidden;
    position: absolute;
    left: 16px;
    top: 0;
    right: 16px;
    height: 60px;
    padding: 12px 0;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    background-color: #FFF;
    align-items: center;
  }
  &__search-wrapper {
    flex: 1;
    margin-right: 16px;
    background: #F7F7F7;
    border: 1px solid #F0F0F0;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 10px 8px;
  }
  &__search-input {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    padding: 0;
    background-color: transparent;
    border-left: 1px solid #D0D0D0;
    padding-left: 4px;
    border-radius: 0;
    &::placeholder {
      color: #707273;
    }
    &:focus {
      outline: none;
    }
  }

  &__list {
    background-color: #F7F7F7;
    transition: height ease .3s 0s;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__navs {
    margin-top: 1px;
    padding-right: 8px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #FFF;
  }
  &__nav {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    gap: 8px;
    color: #049fd9;
    position: relative;

    &:last-of-type {
      color: #212529;
    }
  }
  &__nav-divide {
    position: relative;
    left: 0;
    top: 0;
    width: 14px;
    height: 48px;
    &::before {
      content: "";
      position: absolute;
      display: block;
      border-style: solid;
      border-width: 24px 0 24px 14px;
      border-color: transparent transparent transparent #E9E9E9;
    }
    &::after{
      content: "";
      position: absolute;
      display: block;
      border-style: solid;
      border-width: 24px 0 24px 14px;
      border-color: transparent transparent transparent #FFF;
      left: -1px;
    }
  }

  &__items {
    background-color: #FFF;
    color: #414344;
    flex: 1;
    overflow: auto;
  }
  &__item {
    padding: 16px;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    gap: 12px;
    text-decoration: none;
    color: #414344;
    &.active {
      color: #049fd9;
    }
    &--result {
      font-size: 14px;
      line-height: 20px;
    }
    strong {
      font-weight: 500;
    }
    span {
      flex: 1;
    }
  }
  &__item-arrow {
    align-self: center;
  }
  &__item-icon {
    min-width: 48px;
    height: 20px;
    text-transform: uppercase;
    padding: 4px 2px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    color: #FFF;
    text-align: center;
    background-color: #29B6F6;
    font-style: normal;
    &--icon-get {
      background-color: #66BB6A;
      &::after {
        content: "get";
      }

    }
    &--icon-post {
      background-color: #FFCA2B;
      &::after {
        content: "post";
      }
    }
    &--icon-delete {
      background-color: #EF5350;
      &::after {
        content: "delete";
      }
    }

    &--icon-put {
      &::after {
        content: "put";
      }
    }

    &--icon-patch {
      &:after {
        content: 'patch';
      }
    }
  }

  @keyframes show-doc-label-text {
    0% {
      transform: scale(0) translateX(-30px);
    }
    100% {
      transform: scale(1) translateX(0);
    }
  }

  .pubhub-nav-doc-label-text {
    display: inline-block;
    line-height: 1.5;
    border-radius: 4px;
    padding: 4px 10px 3px 10px;
    font-size: 12px;
    color: #fff;
    transform-origin: left;
    animation: show-doc-label-text 0.3s;
  }
  .pubhub-nav-doc-label-icon.label-warn {
    color: #dc3545;
  }
  .pubhub-nav-doc-label-icon.label-success {
    color: #198754;
  }
  .pubhub-nav-doc-label-icon.label-primary {
    color: #02bceb;
  }
  .pubhub-nav-doc-label-text.label-warn {
    background-color: #dc3545;
  }
  .pubhub-nav-doc-label-text.label-success {
    background-color: #198754;
  }
  .pubhub-nav-doc-label-text.label-primary {
    background-color: #02bceb;
  }
}