.pubhub-discussion-and-events{
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $gray-lightest;

  &-item{
    &:first-child{
      @media (max-width: map-get($grid-breakpoints, sm)){
        margin-bottom: 1rem;
      }
    }
  }
}
