.pubhub-drop-down{
  .pubhub-drop-down-selected{
    height: 40px;
    border: solid 1px #dfdfdf;
    border-radius: 4px;

    .pubhub-drop-down-value{
      font-size: 14px;
      line-height: 40px;
      display: inline-block;
    }

    .pubhub-drop-down-value.pubhub-drop-down-placeholder{
      font-style: oblique;
      color: #86868a;
    }

    .pubhub-drop-down-selector-icon{
      display: inline-block;
      float: right;
      width: 14px;
      height: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("img/drop-down-selector-icon.svg");
    }
    .pubhub-drop-down-selector-icon.pubhub-drop-down-selector-icon-down{
      transform: rotate(180deg);
    }
  }
  .pubhub-drop-down-list{
    max-height: 320px;
    overflow: scroll;
    box-shadow: 2px 6px 3px 0 rgba(0, 0, 0, 0.03);
    border-left: solid 1px #dfdfdf;
    border-right: solid 1px #dfdfdf;
    .pubhub-drop-down-option{
      height: 40px;
      line-height: 40px;
      border-bottom: solid 1px #dfdfdf;
      padding-left: 10px;

      &:hover{
        background-color: #efefef;
      }

    }

  }
}
