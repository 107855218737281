& .swagger{
  .column-code{
    min-height: 0 !important;
    width: 0 !important;
  }

  .widgets{
    margin-right: 0 !important;
    max-width: none !important;
    padding-top: 30px;
  }

}
