.pubhub-search-container {
  border-left: solid 1px #dfdfdf;
  //min-height: 100%;
}

#pubhub-container[data-platform="sm"] .pubhub-doc-container.pubhub-search-container {
  margin-right: 0;
  width: 100%;
}

.pubhub-nav-search {

  position: relative;
  &-box {
    padding: 18px 0px 0px 32px;

    input {
      width: 280px;
      border-radius: 20px;
      border: solid 1px #d8d8d8;
      padding: 8px 28px 8px 12px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.43);
      transition: all .1s;
      line-height: 18px;
      &:focus {
        outline: none;
      }
    }

    //&:after {
    //  content: "\f002";
    //  display: inline-block;
    //  font: normal normal normal 14px/1 FontAwesome;
    //  margin-left: -23px;
    //
    //}

    span {
      display: inline-block;
      width: 14px;
      height: 14px;
      position: absolute;
      right: 30px;
      top: 28px;
      pointer-events: none;
      cursor: pointer;
      background-image: url("../img/search-light.svg");
      background-size: contain;
    }
  }

  &-list {
    position: absolute;
    width: 280px;
    left: 32px;
    border: solid 1px #d8d8d8;
    background: #fff;
    z-index: 1;
    top: 53px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 3px 4px #ccc;
    ul {
    }

    &-item {
      &-active {
        background: rgba(4, 159, 217, 0.2);
      }
    }

    li {
      padding: 5px 10px;
    }

    a {
      font-family: CiscoSans;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
      display: block;
      &:hover {
        color: #049fd9;
      }

      b {
        font-weight: 500;
      }
    }

    a.searchContent {
      padding: 6px 12px;
      display: block;
      border-top: 1px solid #eee;
      margin-top: 6px;
      color: #0088bd;
      font-size: 16px;
      overflow: hidden;
      word-break: break-all;
    }

  }

  &ing {
    .pubhub-nav-search-box {
      input {
        border-radius: 16px 16px 0 0;
      }
      span {
        pointer-events: all;
      }

    }
  }

}

&[data-platform="sm"] {
  .pubhub-nav-search {
    display: none;
  }
}

.pubhub-search {
  max-width: 1140px;
  clear: both;
  padding: 30px;

  h2 {
    font-size: 24px;
    font-family: CiscoSans, Arial, sans-serif;
    margin: 15px 0;
    font-weight: 200;

    b {
      font-weight: 400;
    }
  }

  h4 {
    font-size: 16px;
    font-family: CiscoSans, Arial, sans-serif;
    margin: 15px 0;
    font-weight: 200;
  }

  &-list {
    &-item {
      margin: 20px 0;
      a {
        color: #0088bd;
        font-size: 22px;
        font-family: CiscoSans, Arial, sans-serif;
        text-decoration: none;

        em, mark {
          font-style: normal;
          font-weight: 500;
        }
      }

      &-type {
        display: inline;
        font-size: 11px;
        font-style: oblique;
        color: #666;
        background-color: #eee;
        margin-left: 5px;
        padding: 2px 5px;
        border-radius: 5px;
        vertical-align: 5px;
      }

      &-description {
        line-height: 1.5;
        font-size: 14px;
        color: #6b6b6b;

        em, mark {
          font-style: normal;
          font-weight: 500;
        }
      }

      &-api {
        .api-tags {
          margin: 0 -5px;
          .api-operationid, .api-tag {
            font-size: 12px;
            display: inline-block;
            margin: 4px;
            padding: 2px 5px;
            border-radius: 4px;
            color: #fff;
            background-color: #67b346;
          }
          .api-operationid {
            background-color: #666;
          }
        }

        .api-uri {
          display: inline-block;
          color: #555;
          background-color: #f4f4f4;
          margin: 4px 0;
          padding: 4px 8px;
          border-radius: 3px;
        }
      }
    }
  }

}

.pubhub-search-pagination {
  margin: 10px auto;
  text-align: center;

  .fa {
    display: inline-block;
    font-size: 25px;
    color: #4a4a4a;
    vertical-align: middle;

    &.disabled {
      color: rgba(74, 74, 74, 0.39);

    }
  }

  &-item {
    display: inline-block;
    margin: 0px 5px;
    a {
      display: inline-block;
      border-radius: 18px;
      font-family: CiscoSans;
      font-size: 14px;
      text-align: left;
      color: #4a4a4a;
      padding: 3px 9px;
      text-decoration: none;
    }

    &-active {
      a {
        background-color: #f4f4f4;
      }
    }

  }

}
