$nav_width: 332px;
$nav-arrow-left: 10px;
$nav-arrow-width: 6px;

.pubhub-nav-container {
  background: #fff;
  transition: left .6s ease;
  width: $nav_width;
  border-right: solid 1px #dfdfdf;
  overflow: hidden;
  padding-right: 0px;
  position: fixed;

  &.navigate {
    margin-right: -1px;
    left: 0;
  }

  &.full {
    margin-left: -$nav_width;

    &.open {
      left: 0px;
      box-shadow: 10px 10px 20px 0px #ccc;
    }

  }
}


.pubhub-nav-title {
  display: none;

  .pubhub-nav-doc-label-icon {
    cursor: pointer;
    display: inline-block;
    padding-right: 8px;

    &.label-warn {
      color: #dc3545;
    }
    &.label-success {
      color: #198754;
    }
    &.label-primary {
      color: #02bceb;
    }
  }

  @keyframes show-doc-label-text {
    0% {
      transform: scale(0) translateX(-30px);
    }
    100% {
      transform: scale(1) translateX(0);
    }
  }

  .pubhub-nav-doc-label-text {
    display: inline-block;
    line-height: 1.5;
    border-radius: 4px;
    padding: 4px 10px 3px 10px;
    font-size: 12px;
    color: #fff;
    transform-origin: left;
    animation: show-doc-label-text 0.3s;

    &.label-warn {
      background-color: #dc3545;
    }
    &.label-success {
      background-color: #198754;
    }
    &.label-primary {
      background-color: #02bceb;
    }
  }
}

.pubhub-nav-body {
  width: $nav_width;
}

.pubhub-nav-scroll {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .26);
  }

}

ul.pubhub-nav-items {
  padding-top: 20px;


  & a {
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    line-height: 1.3em;
    padding: 6px 28px 6px 0;
    position: relative;

    &:hover{
      background-color: rgba(0, 162, 211, 0.05);
      color: $cisco-blue;
    }
  }

  & > li {
    //Root Parent
    margin-bottom: 15px;

    & > a {
      display: block;
      font-weight: 400;
      padding-top: 0px;
      font-size: 19px;
      color: $dark_gray_1;
      padding-left: 32px; //level0

    }
  }

  & .fa-external-link:before {
    padding-left: 10px;
  }
  & li[data-expand="true"]{
    & > ul {
      display: block;
    }
  }

  & li[data-expand="false"]{
    & > ul {
      display: none;
    }
  }

  & li.active {
    & > ul {
      display: block;
    }

    & > a {
      background: rgba($cisco-blue, .05);
      color: $cisco-blue;

      & > .arrow {
        border-color: $cisco-blue;
        transform: rotate(45deg);
      }
    }

    & > a:last-child {
      background: none;
    }
  }


  & .arrowContainer {
    height: 16px;
    width: 16px;
    display: inline-block;
    position: absolute;
    top: calc(50% - 8px);
    right: $nav-arrow-left;
    cursor: pointer;
    padding: 4px;
  }

  & .arrow {
    display: block;
    width: $nav-arrow-width;
    height: $nav-arrow-width;
    border-right: solid 1px $light-gray-2;
    border-bottom: solid 1px $light-gray-2;
    transition: transform 0.6s;
    transform: rotate(-45deg);

    &.active {
      transform: rotate(45deg);
    }
  }

}

.pubhub-nav-container > ul.pubhub-nav-items {
  & > li {
    &.active > a {
      color: $dark_gray_1 !important;
      background: none !important;
    }
  }
}

.pubhub-nav-items > li > ul > li {
  display: block;
  position: relative;

  & > a {
    padding-left: 50px; //level1
    color: $dark-gray-3;
    font-size: 17px;
    display: block;
    font-weight: 300;

    &:empty{
      display: none;
    }

    &:hover {
      color: $cisco-blue;

      .arrow {
        border-color: $cisco-blue;
      }
    }
  }

  /* children items */
  & > ul {
    //padding-left: 50px; //level1 padding for container
    display: none;

    & > li {
      //padding-left: 50px;
      & > a {
        padding-left: 68px; // level2 = level1(50) + own(18)
        font-weight: 300;
        font-size: 14px;
        color: $dark-gray-3;
        display: block;
        background: none;
      }

      & > ul {
        //padding-left: 32px;
        display: none;

        li {


          & > a {
            padding-left: 86px; // level3 = (level1(50)+ level2(18) + own(18))
            font-weight: 300;
            font-size: 14px;
            color: $dark-gray-3;
            display: block;
            background: none;
          }

          & > ul > li {
            a {
              padding-left: 100px;
            }

            & > ul > li {
              a {
                padding-left: 114px;
              }

              & > ul > li {
                a {
                  padding-left: 128px;
                }
              }
            }
          }


          & > .pubhub-header-nav {
            margin-left: 86px;

            a {
              padding-left: 0px;
            }
          }

          & > ul {
            display: none;
          }
        }
      }

      & > .pubhub-header-nav {
        margin-left: 68px;

      }

    }
  }
}

ul.pubhub-nav-items > li > a {
  background: transparent !important;
}


div.pubhub-header-nav {
  margin-left: 50px;
  border-left: 1px solid #049fd9;
  position: relative;
  max-height: 9999px;
  transition: 10s all;

  & li {
    padding: 5px 18px 0px 10px;
    line-height: 1.3em;

    &[data-tag-type=h2] {
      padding: 5px 18px 0px 20px;

      & :before {
        left: -20px !important;
      }
    }

    &[data-tag-type=h3] {
      padding: 5px 18px 0px 30px;

      & :before {
        left: -30px !important;
      }
    }

    &:first-child {
      padding-top: 0px;

    }

    &:last-child {
      padding-bottom: 0px;
    }

  }

  & li > a {
    font-size: 14px;
    padding: 0 2px 0 16px;
    color: #58585b;
    line-height: 1.3em;
    display: inline-block;
  }

  & li.active {

    & > a {
      font-weight: 500;
      color: #58585b;
      padding-right: 1px;
    }

  }

  .arrow-indicator {
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid #049fd9;
    width: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    top: 3px;
    transition: 0.6s all cubic-bezier(.87, -.41, .19, 1.44);
  }

  &.animate-leave-active {
    max-height: 0px;
  }

  &.animate-enter {
    max-height: 0px;

    &-active {
      max-height: 9999px;
    }
  }

}

.doc-header-parent {
  position: relative;
  width: 100%;
  background: tranparent;
  font-weight: 300;
  font-size: 14px;

  & > ul.pubhub-header-nav {
    position: relative;
    border-left: 1px solid #049fd9;

    & > li {
      padding: 5px 0px 0px 10px;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }


      & ul {
        padding-left: 10px;

        & li {
          padding-left: 5px;
          padding-top: 5px;
        }
      }
    }

  }

}

.hyperlink:after {
  background: url('img/new_page.svg') center center no-repeat;
  width: 26px;
  height: 24px;
  display: inline-block;
  content: " ";
  position: absolute;
  right: 6px;
}


&[data-platform="sm"] {

  ul.pubhub-nav-items .arrowContainer {
    top: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;
  }

  .pubhub-nav-container.navigate {
    width: 100%;
    border: 0 none;
    z-index: 10;
    position: sticky;
    overflow: visible;
    z-index: 99;
  }

  .pubhub-nav-scroll {
    position: fixed;
    width: 100%;
    background: #0D274D;
    transition: left 0.6s ease;
    left: 100%;
    overflow: hidden;
    // max-height: calc(100vh - 108px);
    // margin-top: 57px;
    z-index: 100;
    box-sizing: border-box;
    //bottom: 0;

    &.show {
      left: 0;
      overflow: auto;
    }

  }


  .pubhub-nav-body {
    width: auto !important;
    padding-bottom: 10px;

  }

  ul.pubhub-nav-items {
    //padding-bottom: 120px !important;
  }

  ul.pubhub-nav-items a {
    color: #fff !important;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      background-color: inherit;
    }
  }

  ul.pubhub-nav-items > li {
    margin-bottom: 8px;
  }
  ul.pubhub-nav-items > li > a {
    font-size: 20px;
  }
  ul.pubhub-nav-items > li > ul > li > a {
    font-size: 16px;
    &:hover .arrow {
      border-color: #c6c7ca;
    }
  }
  .pubhub-nav-items > li > ul > li > ul > li > a {
    font-size: 16px;
  }
  ul.pubhub-nav-items > li > ul > li > ul > li > ul li > a {
    font-size: 16px;
  }

  ul.pubhub-nav-items li.active > a {
    background-color: rgba(255, 255, 255, 0.25) !important;
    // padding-top: 8px;
  }

  ul.pubhub-nav-items .pubhub-header-nav-list li.active > a {
    background: transparent !important;
  }

  ul.pubhub-nav-items .nav-item-icon {
    &.icon-get::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-post::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-delete::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-put::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-patch::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-head::after {
      width: 50px;
      font-size: 12px;
    }
    &.icon-options::after {
      width: 50px;
      font-size: 12px;
    }
  }

  .hyperlink:after {
    background: url('img/external-link-white.svg') center center no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    right: 12px;
  }

  div.pubhub-header-nav {
    li[data-tag-type=h2], li[data-tag-type=h1] {
      padding-top: 0;
    }
  }
  div.pubhub-header-nav .arrow-indicator {
    display: none !important;
  }
  

  .pubhub-nav-title {
    height: 57px;
    display: block;

    &-wrapper {
      background-color: #ffffff;
      border-bottom: solid 1px #d8d8d8;
      // position: fixed;
      align-items: center;
      width: 100%;
      z-index: 100;
      height: 57px;
      display: flex;
    }

    &-text {
      flex: 1 auto;
      display: flex;
      // align-items: center;
      padding: 0 16px;
    }

    &-icon {
      display: inline-block;
      width: 25px;
      height: 100%;
      margin-right: 14px;
      background: url('img/mobile-menu.svg') center center no-repeat;
      cursor: pointer;
      background-size: contain;

      &.close {
        background: url('img/close.svg') center center no-repeat;
        background-size: contain;
      }

    }

  }
}



ul.pubhub-nav-items .deprecated {
  text-decoration: line-through;
  color: #707273;
  .nav-item-icon {
    &.icon-get, &.icon-post, &.icon-put, &.icon-patch, &.icon-delete {
      &::after {
        background-color: #E2E2E2;
        color: #A0A1A1; 
      }
    }
  }
}
ul.pubhub-nav-items .nav-item-icon {

  &.icon-get {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'GET';
      color: #fff;
      border-radius: 4px;
      background: #66bb6a;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-post {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'POST';
      color: #fff;
      border-radius: 4px;
      background: #FFCA2B;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-delete {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'DELETE';
      color: #fff;
      border-radius: 4px;
      background: #ef5350;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-put {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'PUT';
      color: #fff;
      border-radius: 4px;
      background: #29b6f6;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-patch {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'PATCH';
      color: #fff;
      border-radius: 4px;
      background: #29b6f6;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-head {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'HEAD';
      color: #fff;
      border-radius: 4px;
      background: #81c784;
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }

  &.icon-options {
    margin-right: 10px;

    &:after {
      display: inline-block;
      content: 'OPTIONS';
      color: #fff;
      border-radius: 4px;
      background: #8c9eff;
      text-transform: uppercase;
      font-size: 9px;
      padding: 1px 0;
      width: 44px;
      text-align: center;
    }
  }


}

.pubhub-nav-items [data-url-type="oas"] {
  //[data-url-type="oas-api"]{
  //  margin-left: -40px;
  //}

  [data-tag="navigation-item"] {
    //padding-left: 32px;
    display: flex;
  }
}
