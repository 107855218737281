.pubhub-hackit {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 3.75rem;
  padding-bottom: 5.5rem;
  color: #fff;
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-bottom: 4.375rem;
  }

  &-card {
    padding: 25px 30px 30px 30px;
    border-radius: 4px;
    background-color: rgba(59, 59, 59, .8);
    margin-top: 1em;

    .pubhub-info-card-title{
      &::after{
        content: '';
        display: inline-block;
        margin-left: 8px;
        border-left: solid 5px #fff;
        border-top: solid 5px transparent;
        border-bottom: solid 5px transparent;
        border-radius: 2px;
      }
  
      a{
        color: #fff;
  
        &:hover{
          text-decoration: none;
        }
      }
    }
    
    .pubhub-info-card-subtitle{
      font-weight: bold;
      margin-bottom: 6px;
    }
  
    .pubhub-info-card-description{
      height: 44px;
      margin-bottom: 10px;
      font-weight: 300;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  
    .pubhub-hackit-card-list-items{
      padding: 0;
    }

    .pubhub-hackit-card-list-item{
      font-weight: 200;
      margin: 8px 0;
      list-style-position: inside;
      list-style-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20fill%3D%27%232300A994%27%20width%3D%2718%27%20height%3D%2714%27%20viewBox%3D%270%200%2018%2014%27%3E%3Cpath%20fill%3D%27%2300A994%27%20d%3D%27M17.707.293a.999.999%200%200%200-1.414%200L6%2010.586%201.707%206.293A.999.999%200%201%200%20.293%207.707L6%2013.414%2017.707%201.707a.999.999%200%200%200%200-1.414%27/%3E%3C/svg%3E")
    }
  
    .pubhub-info-card-image img{
      max-width: 100%;
      margin: 1rem 0;
    }
  }

  .pubhub-info-card-title{
    text-align: center;
    color: #fff;
  }

  .pubhub-hackit-card .pubhub-info-card-title{
    text-align: left;
  }
}