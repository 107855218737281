.sic {
  position: relative;
}
.sic-detail {
  position: relative;
  border-radius: 4px;
  margin-bottom: 30px;
  border: solid 1px #f2f2f2;
  background: #fff;
  transition: all 0.6s;
  display: block;
  box-shadow: 0px 0px 5px 1px rgba(226, 226, 226, 0.51);
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 5px 1px #e2e2e2;
    text-decoration: none !important;
  }

  &-bg {
    height: 120px;
    object-fit: contain;
    background-color: #e5e5e5;
  }

  &-platform {
    font-family: CiscoSans;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    text-align: right;
    color: #ffffff;
    padding-right: 16px;
    margin-top: -22px;
  }

  &-title {
    font-family: CiscoSans;
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #00ace1;
    height: 60px;
    margin: 86px 20px 13px 20px;

    overflow: hidden;
  }

  &-description {
    margin: 0 20px 18px 20px;
    font-family: CiscoSans;
    font-size: 14px;
    line-height: 1.43;
    color: #4a4a4a;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  &-certs {
    margin: 0 20px 34px 20px;
    display: flex;
    align-items: center;
    min-height: 51px;
  }
}

.sic-logo {
  position: absolute;
  top: 50px;
  height: 120px;
  width: 120px;
  background-color: #fff;
  border: solid 1px #dad8d8;
  border-radius: 60px;
  margin-left: 20px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 120px;
  overflow: hidden;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 5px 1px #e2e2e2;
  }

  img {
    max-width: 80px;
    max-height: 80px;
  }
}

.sic-company-link {
  font-family: CiscoSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0;
  text-align: left;
  color: #747474 !important;
  margin: 0 20px;
  position: absolute;
  top: 180px;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }
}

.sic-detail-bg {
  background-size: cover;
  background-position: center center;
}

.sic-detail-types {
  font-family: CiscoSans;
  font-size: 12px;
  margin: 30px 0px 0px 0px;
  background-color: #00ace1;
  color: white;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 3px;
}

$list: networking, cloud, collaboration, datacenter, iot, mobility, security, analyticsautomation;

@each $technology in $list {
  @for $i from 1 through 6 {
    .sic-detail-bg[data-technology="#{$technology}"][data-order="#{$i}"] {
      background-image: url("img/technology/#{$technology}-solution-#{$i}.jpg");
    }
  }
}

$list: dsi marketplace meraki kinetic dnac datacenter security webex;

@each $platform in $list {
  @for $i from 1 through 6 {
    .sic-detail-bg[data-platform="#{$platform}"][data-order="#{$i}"] {
      background-image: url("img/#{$platform}/#{$platform}-solution-#{$i}.jpg");
    }
  }
}

$list: specialization;

@each $platform in $list {
  @for $i from 1 through 6 {
    .sic-detail-bg[data-platform="#{$platform}"][data-order="#{$i}"] {
      background-image: url("img/#{$platform}/#{$i}.jpg");
    }
  }
}

@for $i from 1 through 6 {
  .sic-detail-bg[data-platform="fusion"][data-order="#{$i}"] {
    background-image: url("img/fusion/bg/fusion-#{$i}.jpg");
  }
}

.sic.fusion {
  .sic-detail-platform {
    height: 1.29em;
  }
}
.sic.fusion-service,
.sic.fusion-partner {
  .sic-company-link {
    visibility: hidden;
  }
}
.fusion-partner-certificate {
  display: inline-block;
  width: 51px;
  height: 51px;
  margin-right: 16px;
  background: center / contain no-repeat;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;

  &::after {
    display: block;
    position: absolute;
    left: -42%;
    top: -5%;
    width: 180%;
    height: 180%;
    content: "";
    background: center / contain no-repeat;
    background-color: inherit;
  }

  &.advisor-gold::after, &.advisorgold::after {
    background-image: url("img/fusion/partner/advisor-gold.svg");
  }
  &.advisor-premier::after, &.advisorpremier::after {
    background-image: url("img/fusion/partner/advisor-premier.svg");
  }
  &.advisor-select::after, &.advisorselect::after {
    background-image: url("img/fusion/partner/advisor-select.svg");
  }
  &.dev-gold::after, &.devgold::after {
    background-image: url("img/fusion/partner/dev-gold.svg");
  }
  &.dev-premier::after, &.devpremier::after {
    background-image: url("img/fusion/partner/dev-premier.svg");
  }
  &.dev-select::after, &.devselect::after {
    background-image: url("img/fusion/partner/dev-select.svg");
  }
  &.integrator-gold::after, &.integratorgold::after {
    background-image: url("img/fusion/partner/integrator-gold.svg");
  }
  &.integrator-premier::after, &.integratorpremier::after {
    background-image: url("img/fusion/partner/integrator-premier.svg");
  }
  &.integrator-select::after, &.integratorselect::after {
    background-image: url("img/fusion/partner/integrator-select.svg");
  }
  &.provider-gold::after, &.providergold::after {
    background-image: url("img/fusion/partner/provider-gold.svg");
  }
  &.provider-premier::after, &.providerpremier::after {
    background-image: url("img/fusion/partner/provider-premier.svg");
  }
  &.provider-select::after, &.providerselect::after {
    background-image: url("img/fusion/partner/provider-select.svg");
  }
}
