
.pubhub-hero-banner-static {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 3.75rem;
  padding-bottom: 5.5rem;
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-bottom: 4.375rem;
  }

  .pubhub-info-card-title {
    font-size: 2.625rem;
    font-weight: $headings-font-weight;
    color: $white;
    text-align: center;

  }

  .pubhub-info-card-description {
    margin-top: 0.875rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.56;
    color: $white;
    min-height: 5.25rem;
    font-weight: $font-weight-extra-light;
    
    a {
      color: $white;
      text-decoration: underline;
      &:visited {
        color: $white;
      }
    }
  }

  .pubhub-info-card-link-btn{
    font-size: calculateRem(18px);
  }


  &-link {
    text-align: center;
  }

  .multi-btn-col {
    padding-left: 0;
    padding-right: 0;
    .pubhub-hero-banner-static-btn {
      display: inline-block;
      margin: 8px 15px;
      max-width: calc(100% - 30px);
      overflow: hidden;

      .pubhub-info-card-link-btn {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
}
