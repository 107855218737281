.column-switch-container {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 5px;
  background-color: #ececec;
  padding:13px 20px;
  z-index: 10;
}

.switch {
  position: relative;
  display: block;
  width: 72px;
  height: 26px;
  border-radius: 5px;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: inherit;
  background-color: #21ade0;
}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  transition: inherit;
}
.switch-label:before {
  content: url('img/btn-two-column.svg');
  right: 8px;
  top: 5px;
  color: #aaaaaa;
}
.switch-label:after {
  content: url('img/btn-three-colmn.svg');
  left: 8px;
  top: 5px;
  color: #FFFFFF;
  opacity: 0;
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 36px;
  height: 26px;
  border-radius: 5px;
  background-color: #58585b;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-handle {
  left: 36px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
}


&[data-platform="sm"] {
  .column-switch-container{
    display: none;
  }
}
