.pubhub-doc-code-panel {
  position: fixed;
  right: 0;
  background-color: #202020;
  color: #c0c5ce;
  width: calc(100% - 1140px - 332px);
  min-width: calc(50% - 167px);
  z-index: 9;
  box-shadow: inset -1px -1px 3px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &-deactive {
    background-color: transparent;
    height: 52px !important;
    box-shadow: none;
    width: 122px;
    overflow: hidden;
    min-width: 122px;

  }

  &-hide {
    display:  none;
  }

  &-title {
    font-family: CiscoSans;
    font-size: 17px;
    font-weight: 400;
    color: #636366;
    background-color: #ececec;
    padding: 16px;
    cursor: pointer;
    &:hover{
      color: #59595c;
    }

  }

  &-content {
    overflow: hidden;
    height: calc(100% - 55px);


    .code-tabs{
      height: 100%;
      background: #202020;
    }
    .code-tab-content-container{
      height: calc(100% - 75px);
      overflow: hidden;
      margin-left: 10px;
      margin-top: 10px;
      margin-right: 5px;
      background: #202020;

      &:hover{
        overflow: auto;
      }


      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #000;
        //
        //background: transparent;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #000;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        width: 4px;
        background: #fff;
      }

      .code-tab-content{
        overflow: visible;
      }


    }

    code{
      overflow: visible;
    }

  }

  .code-tab-title-container {
    background-color: #f6f6f6;
    padding-bottom: 20px;
    position: relative;

    &:before {
      width: 100%;
      height: 1px;
      display: inline-block;
      content: " ";
      background: #e7e7e7;
      position: absolute;
      bottom: 19px;
      left: 0px;
    }

  }

  .code-tab-title {
    z-index: 10;
  }
}
