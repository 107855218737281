$link-color: #049fd9;
$link-hover-color: #0088bd;

/* base */

.pubhub-feature-section {
    font-family: CiscoSans;
    font-weight: 300;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    img {
        max-width: 100%;
    }
    .bg-img-cover {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .flex-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .text-row-2 {
        line-height: 1.4;
        min-height: 2.8em;
    }
}


/* button and arrow */

.pubhub-feature-section {
    .type-button {
        display: inline-block;
        border-radius: 4px;
        padding: 10px 24px;
        font-weight: 400;
        transition: border 0.3s, color 0.3s;
        text-align: center;
        color: #fff;
        background-color: $link-color;
        transition: background-color 0.3s, border-color 0.3s;
        &:hover {
            background-color: $link-hover-color;
        }
        &.type-colorful {
            position: relative;
            z-index: 0;
            overflow: hidden;
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0);
                z-index: -1;
                transition: background-color 0.2s;
            }
            &:hover {
                &::before {
                    background-color: rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
    .type-arrow:not(.pubhub-feature-section-card-items) {
        &::after {
            content: '';
            display: inline-block;
            margin-left: 0.5em;
            border-left: solid 7px currentColor;
            border-top: solid 5px transparent;
            border-bottom: solid 5px transparent;
        }
    }
}


/* header and footer */

.pubhub-feature-section {
    padding-bottom: 48px;
    &-header {
        overflow: hidden;
        padding: 26px 0;
        text-align: center;
        overflow: hidden;
        &-icon {
            margin: 24px 0 14px 0;
        }
        &-title {
            font-size: 32px;
            padding: 12px 0;
            line-height: 1.4;
        }
        &-desc {
            font-size: 18px;
            line-height: 1.25;
            padding: 12px 0;
            line-height: 1.4;
        }
        &-action {
            margin: 12px 0;
            &.type-button {
                margin: 16px 0;
            }
        }
        @media (min-width: 768px) {
            padding: 32px 0;
            &-icon {
                margin: 16px 0;
            }
            &-title {
                font-size: 36px;
            }
        }
        @media (min-width: 992px) {
            padding: 36px 0;
            &-icon {
                margin: 16px 0;
            }
            &-title {
                font-size: 40px;
                padding: 14px 0;
            }
            &-desc {
                padding: 14px 0;
            }
            &-action {
                margin: 14px 0;
                &.type-button {
                    margin: 22px 0;
                }
            }
        }
    }
    &-footer {
        overflow: hidden;
        padding-bottom: 16px;
        &-action {
            margin-top: 16px;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}


/* card */

.pubhub-feature-section {
    &-card-container {
        display: flex;
    }
    &-card {
        overflow: hidden;
        margin-bottom: 32px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        &.align-left {
            text-align: left;
            .type-button {
                align-self: flex-start;
            }
        }
        &.align-right {
            text-align: right;
            .type-button {
                align-self: flex-end;
            }
        }
        &.align-center {
            text-align: center;
            .type-button {
                align-self: flex-center;
            }
        }
        &.has-border {
            border: solid 1px #e4e4e4;
            background-color: rgba(251, 251, 251, 0.7);
            border-radius: 5px;
            padding: 28px;
        }
        &.has-bgcolor {
            background-color: #fff;
            border-radius: 12px;
            padding: 28px;
        }
        &.has-boxshadow {
            box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.12);
            }
        }
        &.img-card {
            background-color: #fff;
            padding: 0;
            border-radius: 0;

            .pubhub-feature-section-card-iconpart {
                margin: 0;
                padding: 0;
            }
                
            .pubhub-feature-section-card-desc {
                padding: 15px 24px;
            }
        }
        &.row-card {
            .pubhub-feature-section-card-icon {
                width: 132px;
            }
        }
        @media (min-width: 576px) {
            &.row-card {
                flex-direction: row;
                padding: 28px 15px;
                text-align: left;

                .pubhub-feature-section-card-iconpart {
                    margin: 0;
                    width: 160px;
                    padding-right: 28px;
                }
            }
        }
        &-iconpart {
            position: relative;
            flex: 0 0 auto;

            &.has-icon {
                margin: 12px 0 20px 0;
            }
        }
        &-icon-title {
            font-weight: 500;
            text-align: left;
            padding-bottom: 8px;

            &.in-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 12px 15px;
                color: #fff;
                font-weight: 300;
                font-size: 24px;
            }
        }
        &-icon-container {
            display: block;

            &.align-left {
                text-align: left;
            }
            &.align-right {
                text-align: right;
            }
            &.align-center {
                text-align: center;
            }
        }
        &-textpart {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
        &-title {
            font-weight: 400;
            font-size: 30px;
            line-height: 1.4;
            margin: 4px 0;
        }
        &-desc {
            padding-top: 12px;
            margin: 4px 0 8px 0;
            text-align: left;
            line-height: 1.4;
        }
        &-items {
            margin: 0;
            padding: 0;
            list-style-position: outside;
            list-style-type: none;
            &.type-bullet {
                list-style-type: disc;
                padding-left: 18px;
            }
            &.type-check,
            &.type-check-disabled {
                padding-left: 26px;
                list-style-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20width%3D%2718%27%20height%3D%2714%27%20viewBox%3D%270%200%2018%2014%27%3E%3Cpath%20fill%3D%27%2300A994%27%20d%3D%27M17.707.293a.999.999%200%200%200-1.414%200L6%2010.586%201.707%206.293A.999.999%200%201%200%20.293%207.707L6%2013.414%2017.707%201.707a.999.999%200%200%200%200-1.414%27/%3E%3C/svg%3E");
                li {
                    padding-left: 4px;
                }
            }
            &.type-arrow {
                .pubhub-feature-section-card-item {
                    &::after {
                        content: '';
                        display: inline-block;
                        margin-left: 0.5em;
                        border-left: solid 7px currentColor;
                        border-top: solid 5px transparent;
                        border-bottom: solid 5px transparent;
                    }
                }
            }
        }
        &-item {
            display: block;
            margin: 8px 0;
            text-align: left;
            line-height: 1.4;
        }
        &-flex-gap {
            flex: 1 1 auto;
        }
        &-action {
            margin-top: 16px;
        }
        .type-button {
            align-self: center;
            &.align-left {
                align-self: flex-start;
            }
            &.align-right {
                align-self: flex-end;
            }
            &.align-center {
                align-self: center;
            }
        }
    }
}


/* utility */

.pubhub-feature-section,
.pubhub-feature-section .pubhub-feature-section-header,
.pubhub-feature-section .pubhub-feature-section-header-icon-container,
.pubhub-feature-section .pubhub-feature-section-header-title,
.pubhub-feature-section .pubhub-feature-section-header-desc,
.pubhub-feature-section .pubhub-feature-section-footer,
.pubhub-feature-section .pubhub-feature-section-card-icon-title,
.pubhub-feature-section .pubhub-feature-section-card-icon-container,
.pubhub-feature-section .pubhub-feature-section-card-title,
.pubhub-feature-section .pubhub-feature-section-card-desc,
.pubhub-feature-section .pubhub-feature-section-card-item,
.pubhub-feature-section .pubhub-feature-section-card-action {
    /* align */
    &.align-left {
        text-align: left;
    }
    &.align-right {
        text-align: right;
    }
    &.align-center {
        text-align: center;
    }
    &.align-stretch {
        img {
            width: 100%;
        }
    }
    /* font-weight */
    &.font-weight-lighter {
        font-weight: 200;
    }
    &.font-weight-light {
        font-weight: 300;
    }
    &.font-weight-normal {
        font-weight: 400;
    }
    &.font-weight-bold {
        font-weight: 500;
    }
    &.font-weight-bolder {
        font-weight: 600;
    }
    /* font-size */
    &.font-size-xs {
        font-size: 16px;
    }
    &.font-size-sm {
        font-size: 20px;
    }
    &.font-size-md {
        font-size: 24px;
    }
    &.font-size-lg {
        font-size: 28px;
    }
    &.font-size-xl {
        font-size: 32px;
    }
    /* padding */
    &.padding-ver-no {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.padding-ver-xs {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    &.padding-ver-sm {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    &.padding-ver-md {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &.padding-ver-lg {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    &.padding-ver-xl {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &.padding-top-no {
        padding-top: 0;
    }
    &.padding-top-xs {
        padding-top: 4px;
    }
    &.padding-top-sm {
        padding-top: 6px;
    }
    &.padding-top-md {
        padding-top: 8px;
    }
    &.padding-top-lg {
        padding-top: 12px;
    }
    &.padding-top-xl {
        padding-top: 16px;
    }
    
    &.padding-bottom-no {
        padding-bottom: 0;
    }
    &.padding-bottom-xs {
        padding-bottom: 4px;
    }
    &.padding-bottom-sm {
        padding-bottom: 6px;
    }
    &.padding-bottom-md {
        padding-bottom: 8px;
    }
    &.padding-bottom-lg {
        padding-bottom: 12px;
    }
    &.padding-bottom-xl {
        padding-bottom: 16px;
    }
}