$arrow-border: solid 2px rgba(255, 255, 255, 0.5);
$slide-height: 350px;

.pubhub-rotating-banner{
  position: relative;
  background-color: #777;

  .pubhub-slider{
    height: $slide-height;
  }

  &-page{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: $slide-height;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    color: #fff;

    &-title{
      line-height: 1.2;
      font-size: 28px;
      font-weight: 200;
      text-shadow: none;
      letter-spacing: 0.1px;
      margin-bottom: 20px;
    }

    &-description{
      min-height: 50px;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    &-action, &-action-item {
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
      background-color: #049fd9;
      color: #ffffff !important;
      font-size: 16px;
      font-weight: 200;
      border-radius: 0.25em;
      padding: 0.75em 2em;
      min-width: 205px;

      &:hover {
        text-decoration: none;
      }

      &.outline-button {
        background-color: rgba(255,255,255, 0.15);
        border: solid 1px;
        padding: calc(0.75em - 1px) calc(2em - 1px);
        transition: background-color 0.3s;

        &:hover {
          background-color: rgba(255,255,255, 0.25);
        }
      }
    }

    &-actions {
      margin: -5px -15px;
    }

    &-action-item {
      margin: 5px 15px;
    }

    &-content{
      width: 80%;
    }
  }

  &-prev, &-next{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 15%;
    max-width: $slide-height;
    cursor: pointer;

    &::before{
      position: absolute;
      content: '';
      display: block;
      box-sizing: content-box;
      width: 40%;
      padding-bottom: 40%;
      top: 50%;
      left: 50%;
    }
  }

  &-prev{
    left: 0;
    background: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);

    &::before{
      border-top: $arrow-border;
      border-left: $arrow-border;
      transform: translate(-22%, -50%) rotate(-45deg);
    }
  }

  &-next{
    right: 0;
    background: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);

    &::before{
      border-top: $arrow-border;
      border-right: $arrow-border;
      transform: translate(-72%, -50%) rotate(45deg);
    }
  }
}

@media (min-width: 574px){
  .pubhub-rotating-banner{
    &-page-title{
      font-size: 32px;
      margin-bottom: 24px;
    }
    &-page-description{
      font-size: 16px;
      margin-bottom: 25px;
    }

    &-prev, &-next{
      &::before{
        width: 30%;
        padding-bottom: 30%;
      }
    }
  }
}

@media (min-width: 768px){
  .pubhub-rotating-banner{
    &-page-title{
      font-size: 40px;
      margin-bottom: 18px;
    }
    &-page-description{
      font-size: 20px;
      margin-bottom: 22px;
    }

    &-prev, &-next{
      &::before{
        width: 23%;
        padding-bottom: 23%;
      }
    }
  }
}

@media (min-width: 992px){
  .pubhub-rotating-banner{
    &-page-title{
      font-size: 48px;
      margin-bottom: 12px;
    }
    &-page-description{
      margin-bottom: 18px;
    }
  }
}