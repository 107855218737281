.pubhub-single-video {
    padding: 80px 0 70px 0;
    color: #4b4b4b;

    @media (min-width: 768px) {
        padding: 80px 0;
    }

    &-title {
        margin: -35px 0 30px 0;
        text-align: center;
        font-size: 38px;
        font-weight: 200;
    }

    &-subtitle {
        margin: 25px 0 10px 0;
        font-size: 18px;
        font-weight: 400;
        
        @media (min-width: 768px) {
            margin-top: 0;
        }
    }
    
    &-desc {
        color: #5a5a5a;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;
    }

    &-iframe-container {
        position: relative;
        padding-bottom: 56%;
        background-color: #eee;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}