.pubhub-subscription{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-image: url('./img/bg.jpg');
    padding: 40px 0 60px 0;

    &-title{
        font-size: 48px;
        font-weight: 200;
        line-height: 1.2;
        margin: 20px 0;
        color: #39393b;
    }

    &-subtitle{
        font-size: 18px;
        line-height: 1.3;
        color:lighten(#4a4a4a, 25%);
        margin-bottom: 30px;
    }

    &-reg-action{
        margin: 0 0 1rem 0;
        color: #fff !important;
    }

    &-benefits{
        &-title{
            color: #39393b;
            font-size: 18px;
            margin-bottom: 10px;
        }

        ul{
            column-width: 300px;
            max-width: 700px;
            margin-left: -20px;
            padding: 0;
        }

        li{
            margin-left: 20px;
            color: #5a5a5a;
            font-size: 14px;
            line-height: 1.9;
            list-style-position: inside;
            list-style-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20fill%3D%27%232300A994%27%20width%3D%2718%27%20height%3D%2714%27%20viewBox%3D%270%200%2018%2014%27%3E%3Cpath%20fill%3D%27%2300A994%27%20d%3D%27M17.707.293a.999.999%200%200%200-1.414%200L6%2010.586%201.707%206.293A.999.999%200%201%200%20.293%207.707L6%2013.414%2017.707%201.707a.999.999%200%200%200%200-1.414%27/%3E%3C/svg%3E");
        }
    }
}