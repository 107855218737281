/*
    Basic TAB styles
    (Can be overwritten in apps)
*/

.code-tab-container {

  margin: 20px 0;

  .code-tab-title {
    background-color: #f3f3f3;
    padding: 16px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #e4e4e4;
    }

    i.fa-file-code-o {
      color: #58585b;
      font-size: 20px;
    }

    span {
      display: inline-block;
      font-family: CiscoSans;
      font-size: 24px;
      text-align: left;
      color: #58585b;
      margin-left: 20px;
      margin-right: 20px;
      flex: 3 0px;
    }

    i.fa-angle-right {
      color: #58585b;
      font-size: 32px;
    }
  }



  &-active {
    .code-tab-title {
      background-color: #eef9fc !important;

      i.fa-file-code-o {
        color: #049fd9;
      }
      span {
        color: #049fd9;
      }
      i.fa-angle-right {
        color: #049fd9;
      }
    }
  }

  .code-tabs {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      border-right: 16px solid #202020;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      left: -16px;
      top: 62px;
      display: none;
    }

  }

}

.code-tab-title-container {
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 10px;
}

.code-tab-title-container .code-tab-title {
  background: none;
  padding: 8px 0px;
  margin: 0px 10px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: #636366;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: -2px;
  background: transparent !important;

}

.code-tabs .code-tab-title-container .code-tab-title:first-child {
  border-bottom: 3px solid #049fd9;
}

.code-tabs[data-index] .code-tab-title-container .code-tab-title:first-child {
  border-bottom: 0 none;
}

.code-tabs[data-index] .code-tab-title-container .code-tab-title.current {
  border-bottom: 3px solid #049fd9 !important;
}

.code-tab-content-container {
  padding-top: 8px;
  overflow: auto;
}

.code-tab-content {
  display: none;
  background: #202020;
  padding: 20px 20px;
  margin-bottom: 50px;
  color: #fff;
  position: relative;

  &.line-numbers {
    padding-left: 3.8em;
  }

  & > pre {
    background: transparent;
    font-family: "CiscoSans", "Arial", sans-serif;
    font-weight: 200;
  }

  h5 {
    color: #fff;
    font-family: "CiscoSans", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }
  
  .copy-to-clipboard {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    color: #ececec;
    z-index: 1;
    &:hover {
      color: grey;
    }
  }
}

.code-tabs .code-tab-content-container .code-tab-content:first-child {
  display: inherit;
}

.code-tabs[data-index] .code-tab-content-container .code-tab-content:first-child {
  display: none;
}

.code-tabs[data-index] .code-tab-content-container .code-tab-content.current {
  display: inherit !important;
}

.tooltips:after {
  content: '';
  position: absolute;
  border-right: 16px solid #202020;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

.one-column .fa-angle-right {
  display: none;
}

.two-columns .code-tab-title {
  padding: 16px 23px 16px 30px;
  margin-right: -30px;
  margin-left: -30px;
}


