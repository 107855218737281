.pubhub-breadcrumb {
  line-height: calculateRem(59px);
  background-color: #fbfbfb;
  opacity: .95;

  .container{
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }


  &-breadcrumb{
    ul{
      margin: 0;
      padding: 0;
    }

    li{
      list-style: none;
      display: inline-block;
    }

    a{
      color: #4f4f4f!important;
      font-size: 15px;
    }

    &-arrow:before{
      padding: 0 8px;
      color: #4f4f4f;
      content: "\003e";
      display: inline-block;
    }

    &-arrow:first-child:before{
      display: none;
    }


    &-title a{
      font-weight: 500;
    }

    &-navi-title:last-child a{
      font-weight: 500;
    }

    &-large-title a{
      font-size: 23px;
      font-weight: 200;
      color: #333333 !important;
    }

  }




  .pubhub-breadcrumb-nav {

    .pubhub-breadcrumb-links {

      overflow: auto;

      &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0);
      }



      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        @media (max-width: map-get($grid-breakpoints, md)) {
          justify-content: flex-start;
        }

      }

      li {
        list-style: none;
        padding: 0;
      }

      a {
        font-family: CiscoSans;
        font-size: calculateRem(14px);
        color: #333333;
        padding: calculateRem(16px);
        //padding-right: calculateRem(17px);
      }
    }

  }

}
