$tiny: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$darkblue: #163c54;
$lightblue: #049fd9;
$lightgreen: #9fff80;

.pubhub-drawing {
    background-color: $darkblue;
    position: relative;
    z-index: 10000;

    .loading-container {
        text-align: center;
    }

    .abstract-left {
        background-image: url("./img/abstract-left-mobile.svg");
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 80px;
        height: 100%;
        position: absolute;
        width: 100%;

        @media (min-width: $sm) {
            background-size: 160px;
        }

        @media (min-width: $md) {
            background-image: url("./img/abstract-left.svg");
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    .abstract-right {
        background-image: url("./img/abstract-right-mobile.svg");
        background-position: top right;
        background-repeat: no-repeat;
        background-size: 75px;
        height: 100%;
        position: absolute;
        width: 100%;

        @media (min-width: $sm) {
            background-size: 100px;
        }

        @media (min-width: $md) {
            background-image: url("./img/abstract-right.svg");
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            position: absolute;
            right: 0;
            width: 100%;
        }
    }

    .inner-contents {
        padding: 30px 0;

        .gift-icon {
            color: #ffffff;
            font-size: 90px;
            margin-bottom: 20px;
        }

        .title {
            color: #ffffff;
            font-family: CiscoSans;
            font-size: 36px;
            font-weight: 200;
            margin-bottom: 20px;
            text-align: center;

            @media (min-width: $sm) {
                font-size: 48px;
            }
        }

        .description {
            color: #ffffff;
            font-family: CiscoSans;
            font-size: 20px;
            font-weight: 200;
            margin-bottom: 40px;
            text-align: center;
        }

        .terms-checkbox-box {
            display: block;
            position: relative;
            padding-left: 25px;
            margin-bottom: 40px;

            .terms-checkbox {
                width: 0;
                height: 0;
                box-sizing: border-box;
                position: absolute;
                opacity: 0;
            }

            .terms-checkbox:checked ~ .terms-checkbox-checkmark {
                background-color: #2196F3;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 5px;
                    top: 2px;
                    width: 4px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }

            .terms-checkbox-checkmark {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                background-color: #fff;
                border: solid 1px #ddd;
                border-radius: 2px;
                box-sizing: border-box;
                
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -14px;
                    right: -14px;
                    top: -14px;
                    bottom: -14px;
                }
            }
        }

        .terms-text {
            color: #ffffff;
            font-family: CiscoSans;
            font-size: 12px;
            font-weight: 200;
        }

        .entered-container {
            margin-bottom: 15px;

            .entered-icon {
                color: $lightgreen;
                font-size: 29px;
                line-height: 0.75;
                margin-right: 20px;
            }

            .entered-text {
                color: $lightgreen;
                font-size: 21px;
                line-height: 1;
            }
        }

        .submit-btn {
            border: 1px solid #ffffff;
            color: $lightblue;
            font-size: 16px;
            margin: 0 auto;
        }

        .submit-btn.is-active {
            cursor: pointer;
            opacity: 1;
        }

        .submit-btn.is-active:hover {
            background-color: transparent;
            color: #ffffff;
        }

        .submit-btn.is-inactive {
            opacity: 0.5;
        }
    }

    .counter-same-width {
        max-width: 100%;
        width: 280px;

        @media (min-width: $tiny) {
            width: 360px;
        }

        @media (min-width: $sm) {
            width: 406px;
        }
    }
}
