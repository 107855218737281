// -----------------------------------------------------------------------------
// Base: Typography

$base-font-family: 'CiscoSans', 'Arial', sans-serif !default;
$monospace-font-family: Monaco, Consolas, 'Courier New', Courier, monospace !default;


// -----------------------------------------------------------------------------
// Base: Font

$html-font-size: 16px !default;
$base-font-size: 14px !default;
$base-font-weight: 300 !default;
$compressed-font-size: 11px;
$small-font-size: 10px !default;
$tiny-font-size: 8px !default;


// -----------------------------------------------------------------------------
// Base: Headings

$h1-font-size: $base-font-size * 3.28 !default;
$h2-font-size: $base-font-size * 2.4 !default;
$h3-font-size: $base-font-size * 1.57 !default;
$h4-font-size: $base-font-size * 1.5 !default;
$h5-font-size: $base-font-size * 1.25 !default;
$h6-font-size: $base-font-size * 1 !default;
$h1-font-weight: 100 !default;
$h2-font-weight: 100 !default;
$h3-font-weight: 200 !default;
$h4-font-weight: 300 !default;
$h5-font-weight: 300 !default;
$h6-font-weight: 400 !default;
$headings: (h1, $h1-font-size, $h1-font-weight),
        (h2, $h2-font-size, $h2-font-weight),
        (h3, $h3-font-size, $h3-font-weight),
        (h4, $h4-font-size, $h4-font-weight),
        (h5, $h5-font-size, $h5-font-weight),
        (h6, $h6-font-size, $h6-font-weight);


// -----------------------------------------------------------------------------
// Base: Other Sizes

$base-line-height: 1.5 !default;
$base-border-radius: 2px !default;
$base-spacing: 15px !default;
$base-z-index: 0 !default;


// -----------------------------------------------------------------------------
// Base: Colors

$blue: #004676 !default;
$light-blue: #007fc5 !default;
$green: #00bb00 !default;
$red: #e74c3c !default;
$popsicle-orange: #ffa000 !default;
$orange: #ff7518 !default;
$yellow: #efb73e !default;
$dark-gray: #555 !default;
$gray: #888 !default;
$light-gray: #e9e9e9 !default;
$off-white: #f9f9f9 !default;

$craterlake-blue: #004baf !default;
$cisco-blue: #049fd9 !default;
$icy-blue: #c4d6ed !default;
$willamette-blue: #097dbc !default;
$icy-blue-2: #f4f7fa !default;

$cisco-green: #abc233 !default;
$fern-green: #00ad0b !default;
$median-green: #008516 !default;
$white: #ffffff !default;
$black: #1a1a1a !default;
$dark-gray-1: #39393b !default;
$dark-gray-2: #58585b !default;
$dark-gray-3: #444444 !default;
$dark-gray-4: #7f7f86 !default;
$med-gray-2: #979797 !default;
$med-gray-3: #b0b0b2 !default;
$med-gray-4: #b6b9bb !default;
$light-gray-2: #c6c7ca !default;
$light-gray-3: #dfdfdf !default;
$light-gray-4: #f5f5f6 !default;
$light-gray-5: #f2f2f2 !default;
$pale-gray-1: #e8ebf1 !default;
$pale-gray-2: #f3f6f9 !default;

$primary-blue: #16a3ff !default;
$status-blue: #64bbe3 !default;
$status-turq: #14a792 !default;
$status-green: #6cc04a !default;
$status-red: #cf2030 !default;
$status-orange: #ff7300 !default;
$status-yellow: #ffcc00 !default;

$default: $gray !default;
$primary: $cisco-green !default;
$secondary: $cisco-blue !default;
$info: $status-blue !default;
$success: $status-green !default;
$danger: $status-red !default;
$warning: $status-orange !default;
$warning-alt: $status-yellow !default;


// -----------------------------------------------------------------------------
// Base: Background Colors

$base-background-color: $icy-blue-2 !default;
$alt-background-color: $white !default;


// -----------------------------------------------------------------------------
// Base: Text Colors

$header-font-color: $dark-gray-1 !default;
$base-font-color: $dark-gray-1 !default;
$base-muted-color: $med-gray-2 !default;
$base-disabled-color: $light-gray-2 !default;
$base-accent-color: $status-blue !default;

$base-link-color: $base-accent-color !default;
$hover-link-color: $base-accent-color !default;
$base-link-color-invert: $light-gray-2 !default;



// -----------------------------------------------------------------------------
// Base: Breakpoints

$breakpoints: (
        'xsmall'  : ( min-width: 479px ),
        'small'   : ( min-width: 767px ),
        'medium'  : ( min-width: 992px ),
        'large'   : ( min-width: 1200px )
);

