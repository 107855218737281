$pubhub-event-border-color: #dddddd;
.pubhub-events{
  border-radius: 0.25rem;
  background-color: white;
  border: solid 0.0625rem $pubhub-event-border-color;
  padding: 2rem;
  height: 100%;

  @media (max-width: map-get($grid-breakpoints, sm)){
    padding: 2rem 0rem 2rem 1rem;
  }

  &-title{
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: $line-height-base*0.587;
    text-align: left;
    color: $blue;
    margin: 0;

    &:after{
      content:' ';
      position:absolute;
      width:0;
      height:0;
      border: 0.375rem solid;
      border-color: transparent transparent transparent $blue;
      margin-left: 0.375rem;
    }
    &:hover:after{
      border-color: transparent transparent transparent $link-hover-color;
    }
  }

  &-list{
    margin-top: 1.6875rem;


    &-item{
      margin-bottom: 0.6875rem;

      &-date{
        //width: 6.875rem;
        height: 4.375rem;
        border-radius: 0.25rem;
        background-color: $gray-lightest;
        border: solid 0.0625rem $pubhub-event-border-color;
        color: $gray-dark;
        padding: 0.625rem 1.3rem 0.375rem;

        &-month{
          font-size: 1.0625rem;
          font-weight: $font-weight-extra-light;

        }

        &-day{
          font-size: 1.5rem;
          font-weight: $font-weight-base;
        }

      }

      &-text{
        @media (min-width: map-get($grid-breakpoints, sm)+1px){
          max-width: calc(100% - 75px);
        }

        &-title{
          display: inline-block;
          max-width: 100%;
          font-size: 1.125rem;
          line-height: $line-height-base*0.8;
          color: $blue;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (min-width: map-get($grid-breakpoints, sm)+1px){
            white-space: nowrap;
          }

          &:hover{
            text-decoration: none;
          }
        }

        &-subtitle{
          font-size: 0.875rem;
          line-height: $line-height-base*0.8;
          color: $gray-dark;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (min-width: map-get($grid-breakpoints, sm)+1px){
            white-space: nowrap;
          }
        }
      }
    }

  }

}
