.pubhub-learning-lab-list {

  &-title{
    .pubhub-info-card-title{
      margin-top: 12px;
    }
  }

  .pubhub-info-card-title {
    color: $gray-dark;
    font-weight: $font-weight-bold;
    margin-bottom: .5rem;
  }

  &-item {
    margin: 1.25rem 0;

    .lab-list-item-link {
      display: inline-block;

      .link-icon {
        vertical-align: middle;
        fill: currentColor;
        margin: -2px 4px 0 0;
      }
    }

    &-tags {
      font-size: .75rem;
      color: $gray-light;
      display: inline-block;
    }

    &-time {
      background: url("./img/icon-clock-o.svg");
      background-repeat: no-repeat;
      //margin-left: 1rem;
      padding-left: 0.875rem;
      font-size: .75rem;
      color: $gray-light;
      line-height: 1.67;
      background-position-y: .125rem;
    }
  }

  .pubhub-info-card-link-btn {
    @media (max-width: map-get($grid-breakpoints, xl)) {
      width: 100%;
    }
  }
}
