.pubhub-version {
  .pubhub-select__control{
    border-color: hsl(0,0%,80%);
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    &:hover {
      border-color: hsl(0,0%,80%);
    }
  }
}
