$creation-carousel-tag-color: #9e9ea2;

.pubhub-creation-carousel{
  background-color: #fafafa;
  padding-top: 3.625rem;
  padding-bottom: 4.875rem;
  &-title{
    font-size: 2.375rem;
    font-weight: $font-weight-extra-light;
    line-height: $line-height-base * 0.98;
    text-align: center;
    color: #4a4a4a;
  }
  &-subtitle{
    font-size: 1.1275rem;
    font-weight: $font-weight-light;
    line-height: $line-height-base * 0.98;
    text-align: center;
    padding-bottom: 10px;
    color:lighten(#4a4a4a, 25%);
  }
  &-container{
    margin-top: 1.875rem;

    &-pointer{
      cursor: pointer;
      width: 2rem;
      height: 2.5rem;
      margin-top: 12.5rem;
      display: inline-block;


      &-left{
        background-image: url("./img/angle-left.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }


      &-right{
        background-image: url("./img/angle-right.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

    }

    @media (max-width: map-get($grid-breakpoints, xl)){
      &-pointer{
        display: none;
      }

      .pubhub-creation-carousel-stage{
        width: 100% !important;
        .pubhub-creation-carousel-item{
          margin-bottom: 2rem;
        }
        .clone{
          display: none;
        }
      }

    }
  }

  .pubhub-creation-carousel-stage{
    width: 100%;
    overflow: hidden;
    padding: 0;

    .pubhub-creation-carousel-panes{
      transform: translate3d(0,0,0);
      padding: 0;
      &.transition{
        transition: .25s;
      }

      @media (min-width: map-get($grid-breakpoints, xl)+1) {
        max-width: none;
        .row{
          margin-left: 0;
          margin-right: 0;
          display: flex;
        }
      }

    }
  }

  &-item{
    @media (min-width: map-get($grid-breakpoints, xl)+1){
      flex: 1;
    }
    vertical-align: middle;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    position: relative;

    &-content{
      border-radius: 4px;
      border: 1px solid #ddd;
      overflow: hidden;
    }

    .pubhub-info-card-image{
      display: block;
      width: 100%;
      height: 218px;
      text-align: center;
      img{
        max-width: 100%;
        height: 218px;
      }
      //background-size: contain;
      //background-repeat: no-repeat;
      //background-position: center center;
    }


    &-text-card{
      height: 14.375rem;
      background-color: $white;
      border: solid 1px $gray-lightest;
      box-sizing: border-box;
      padding: 1.25rem;


      .pubhub-info-card-title-link{
        font-size: 1rem;
        line-height: $line-height-base*0.834;
        color: $blue;
      }

      .pubhub-info-card-subtitle{
        font-size: 0.75rem;
        line-height: $line-height-base * 0.86;
        color: #4a4a4a;
        margin-bottom: 1.25rem;
      }
      .pubhub-info-card-description{
        font-size: 0.875rem;
        line-height: $line-height-base * 0.86;
        color: #4a4a4a;
      }

      &-tags{
        margin-top: 1rem;
        font-size: 0.875rem;
        line-height: $line-height-base * 0.86;
        color: $creation-carousel-tag-color;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

      }
    }
  }
}










