#pubhub-container .doc-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &[tabindex = "0"] {
    cursor: pointer;
  }

  &--expand {
    background-image: url(./icons/expand.svg);
  }

  svg {
    vertical-align: top;
  }
}