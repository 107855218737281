.pubhub-support {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding-bottom: 0rem;
  }

  &-title {
    text-align: center;
    h1{
      padding-bottom: 1.25rem;
      font-size: 2.375rem;
      color: $gray-dark;
      font-weight: $font-weight-extra-light;
    }
  }

  &-content{
    margin-bottom: 4rem;
  }



  &-item{
    margin-bottom: 2rem;

    &-img{
      text-align: center;
      @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-bottom: 1rem;
      }

    }

  }

  .pubhub-info-card-image img {
    max-width: 100%;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      width: 100%;
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 50%;
    }
  }

  .pubhub-info-card-subtitle{
    font-weight: $font-weight-bold;
    color: $gray;
  }

  .pubhub-info-card-title{
    font-size: 1.625rem;
    line-height: 1.2;
    margin-top:0.625rem;
    margin-bottom:0.625rem;
  }

  .pubhub-info-card-description{
    line-height:1.38;
    color: $gray-dark;
  }

  //to embed images in SDK
  .support-ticket {
    background: url("./img/support-ticket.png");
  }

  .support-forum {
    background: url("./img/support-forum.png");
  }

}
