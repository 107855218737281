$an-dur: 0.75s;

@keyframes pba-new-slide-in-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes pba-new-slide-out-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes pba-new-slide-in-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes pba-new-slide-out-right {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.pubhub-new-slider {
  position: relative;
  overflow: hidden;

  .pubhub-new-slider-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;

    &.pubhub-new-slide-in-left {
      visibility: visible;
      transform: translateX(0);
      animation: pba-new-slide-in-left $an-dur;
    }

    &.pubhub-new-slide-out-left {
      visibility: visible;
      transform: translateX(-100%);
      animation: pba-new-slide-out-left $an-dur;
    }

    &.pubhub-new-slide-in-right {
      visibility: visible;
      transform: translateX(0);
      animation: pba-new-slide-in-right $an-dur;
    }

    &.pubhub-new-slide-out-right {
      visibility: visible;
      transform: translateX(100%);
      animation: pba-new-slide-out-right $an-dur;
    }
  }

  .pubhub-new-slider-indicator {
    position: absolute;
    left: 50%;
    bottom: 7%;
    transform: translateX(-50%);

    &-item {
      display: inline-block;
      width: 48px;
      height: 2px;
      border-radius: 0;
      border: solid 1px #ffffff;
      margin: 0 4px;
      opacity: 0.4;
      vertical-align: middle;
      cursor: pointer;

      &.pubhub-new-slider-indicator-item-activated {
        opacity: 1;
        z-index: 1050;
        position: relative;
      }

      .activated-item-progress-bar {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #049fd9;
        border-radius: 0;
        margin: 0 4px;
        vertical-align: middle;
        cursor: pointer;
        top: -1px;
        left: -5px;
        animation: animate-progress-bar 10s linear infinite;
        @keyframes animate-progress-bar {
          0% {
            width: 0;
          }
          100% {
            width: 49px;
          }
        }

      }
    }
  }
}
