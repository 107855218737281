
/*------ begin reset ------*/
html, body, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li, form, label,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//body {
//  line-height:1;
//  width:100%;
//  height:100%;
//  font-family: CiscoSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
//  line-height: 1.428571429;
//  color: #333333;
//  background-color: #ffffff;
//}

*, *:before, *:after {
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

fieldset{
  -moz-border-radius:0.3rem;
  -webkit-border-radius:0.3rem;
  border-radius:0.3rem;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary {
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  border:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:0.1rem dotted #000;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

hr {
  display:block;
  height:0.1rem;
  border:0;
  border-top:0.1rem solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
  border: 0.1rem solid #3FA9F5;
  border-radius: 0.5rem;
}
/*------ end reset ------*/

/*--------base-----------*/
.hide{
  display: none !important;
  width: 0.0rem !important;
  height: 0.0rem !important;
}

.show{
  display: block;
}
/*--------base-----------*/

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */



