$community-container-margin: 130px;
$link-color: #049fd9;
$img-size: 100px;
.pubhub-community-blog {
  padding-top: 2.5rem;
  padding-bottom: 5rem;

  h1 .pubhub-info-card-title {
    text-align: center;
    font-size: 2.375rem;
    color: $gray-dark;
    font-weight: $font-weight-extra-light;
  }

  &-subtitle {

    margin-top: calculateRem(6px);

    .pubhub-info-card-subtitle {
      color: $gray-dark;
      font-weight: $headings-font-weight;
    }

  }

  &-content {

    margin-top: -1rem;

    &-item {
      margin-top: 4rem;
      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-top: 2rem;
      }
    }

    .pubhub-info-card-image img {
      max-width: 6.25rem;
      border-radius: .5rem;
      @media (max-width: map-get($grid-breakpoints, xl)) {
        width: 100%;
        max-height: 100%;
      }
    }

    .pubhub-info-card-title {
      font-size: 1.375rem;
      line-height: 1.3;
      color: $blue;
      margin-bottom: .3rem;
    }

    .pubhub-info-card-subtitle {
      font-size: 0.875rem;
      color: $gray;
      margin-bottom: 1rem;
    }
    .pubhub-info-card-description {
      color: $gray-dark;
      line-height: 1.38;
      margin-bottom: 1rem;
    }

    .pubhub-info-card-link {
      &:after {
        content: ' ';
        display: inline-block;
        width: 0;
        height: 0;
        border: solid 0.375rem;
        border-color: transparent transparent transparent $link-color;
        margin-left: calculateRem(10px)
      }
      &:hover:after{
        border-color: transparent transparent transparent $link-hover-color;
      }
    }
  }

  &-button {
    margin-top: .25rem;
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-top: 1.25rem;
    }
    .col {
      text-align: right;
    }
  }

  .pubhub-info-card-link-btn {
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }

}
