.pubhub-learning-lab{
  padding-top: 2.5rem;
  padding-bottom: 4em;

  &-title{
    margin-bottom: 2rem;
    font-size: 2.375em;
    color: $gray-dark;
    font-weight: $font-weight-extra-light;
    text-align: center;

    text-align: center;
  }

  &-desc {
    font-weight: 300;
    color: #4b4b4b;
    padding-bottom: 4px;
  }

  &-list{
    @media (max-width: map-get($grid-breakpoints, xl)) {
      margin-top: 1.5em;
    }
  }

  .pubhub-llab-item-col {
    margin: 12px 0 20px 0;
  }

}
