.pubhub-youtube{
  &.full-section {
    padding-bottom: 80px;
  }

  &-title{
    padding-top: 2.5rem;
    margin-bottom: 1.25rem;

    h1{
      color: #4b4b4b;
      text-align: center;
    }

    .subtitle {
      color: #4b4b4b;
      text-align: center;
      font-weight: 300;
      padding: 4px 0 10px 0;
    }
  }

  .pubhub-youtube-content{
    .pubhub-loading-container{
      text-align: center;
    }
    &-main{
      padding-bottom: 56.25%;
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      margin-left: -0.9375rem;
      margin-right: -0.9375rem;
      background-color: #eee;
      iframe{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
    &-nav{
      margin-left: -0.9375rem;
      margin-right: -0.9375rem;
      position: relative;
      height: 0;
      overflow-x: hidden;
      overflow-y: auto;

      background-color: black;
      padding-top: 1.25rem;
      padding-bottom: 111.6%;

      &.hide-title {
        padding-top: 0.875rem;
      }

      @media (max-width:map-get($grid-breakpoints, md)) {
        padding-bottom: 0;
        height: 18.75rem;
        &>div{
          position: relative !important;
        }
      }
      &>div{
        position: absolute;
      }

      &-title{
        font-size: 1rem;
        color: white;
        font-weight: 500;
        margin-left: 1.375rem;
        margin-bottom: 0.625rem;
      }

      &-number{
        font-size: 0.875rem;
        color: #777777;
        margin-left: 1.375rem;
        margin-bottom: 0.625rem;
      }

      &-content{
        .pubhub-youtube-content-nav-item{
          color: rgba(255,255,255,0.5);
          opacity: 0.6;
          background: rgba(72,72,72,.6);
          padding: 0.625rem;
          border-bottom: solid 1px rgba(255,255,255,.5);
          cursor: pointer;
          margin: 0;

          @media (max-width:map-get($grid-breakpoints, md)){
            &-order{
              text-align: center;
            }
          }

          &-order{
            font-size: 0.875rem;
            padding: 0;
            text-align: right;
          }
          &-thumbnail{
            width: 100%
          }
          &-title{
            font-size: 0.75rem;
          }
        }

        .pubhub-youtube-content-nav-item:hover,.pubhub-youtube-content-nav-item.pubhub-youtube-content-nav-item-active{
          color: white;
          background: rgba(72,72,72,0.8);
          opacity: 1;
        }
      }

    }
  }

}
