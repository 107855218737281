.btn-lg-wide {
  @include button-size(0.9375rem, 2rem, 0.9375rem, $btn-border-radius-lg);
}

.btn-lg-wider {
  @include button-size(0.9375rem, 3rem, 0.9375rem, $btn-border-radius-lg);
}

.btn-outline-white {
  @extend .btn-outline-primary;
  color: $white !important;
  border-color: $white;

  &:hover, &:focus {
    background: rgba($white, 0.1);
    color: $white;
    box-shadow: 0 0 0 2px rgba($white, 0.1);
    border-color: $white;
  }

  &:active, &:visited {
    background: transparent;
    border-color: $white;
  }
}
