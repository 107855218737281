.pubhub-learning-lab-track {
  margin-top: 12px;
  &-title {
    color: $gray-dark;
    font-weight: $font-weight-bold;
    margin-bottom: .5rem;
  }

  &-item {

    &-title {
      background: $learning-lab-default-title-background-color;
      color: $white;
      border-radius: .25rem .25rem 0 0;
      padding: 0.875rem 1.5rem 0.875rem 1.5rem;

      a {
        color: $white;
        font-size: 1.25rem;
        text-decoration: none;
      }
    }

    &-info {
      padding: 2rem 1.5rem;
      border: solid 1px $learning-lab-border-color;
      background: $learning-lab-background;
    }

    &-description {
      line-height: 1.38;
      color: $gray-dark;
      //min-height: 5.5rem;
      height: 5.5rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &-tags {
      height: 70px;
      overflow: hidden;
      font-size: .875rem;
      color: $blue;
      padding: 1rem;
      background-image: url("./img/tag.svg");
      background-repeat: no-repeat;
      background-origin: content-box;
      background-position-y: 3px;

      .pubhub-learning-lab-module-item-tag-link {
        margin-right: 6px;
        display: inline-block;
        color: #085b7a;
      }

    }

    &-data {
      margin-top: 1rem;
      > .col {
        padding-left: 0.3125rem;
        padding-right: 0.3125rem;
      }
    }

    &-col {
      border: solid 1px $learning-lab-border-color;
      border-radius: .25rem;
      text-align: center;
    }

    &-number {
      display: block;
      font-size: 1.375rem;
      font-weight: $font-weight-extra-light;
      line-height: 1.5;
      text-align: center;
      color: $learning-lab-number;
      margin-top: .5rem;
    }

    &-type {
      display: block;
      font-size: 0.8125rem;
      line-height: 1;
      text-align: center;
      color: $learning-lab-type;
      margin-bottom: .5rem;
    }

  }


  &-top-banner{
    height: 8px;
    background:#0084ae;
    border-radius: 4px 4px 0 0;
  }

}

.pubhub-learning-lab-track-mini {
  .pubhub-learning-lab-track-item-title {
    background: transparent;
    border: solid 1px #dddddd;
    border-bottom: 0 none;
    padding-bottom: 0;
    border-radius: 0;
    border-top:0 none;
    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
      color: #4a4a4a;
      margin-bottom: 0px;
    }
  }

  .pubhub-learning-lab-track-item-info {
    border-top:0 none;
    padding-top: 3px;
  }

  .pubhub-learning-lab-track-item-description {
    opacity: 0.7;
    font-family: 'CiscoSans';
    font-weight: 200;
    font-size: 12px;
    line-height: 1.4;
    color: #4a4a4a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    height: 30px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
  }
  .pubhub-learning-lab-track-item-tags {
    display: none;
  }



}
