@import "common/common";
@import "font";

//doc
#pubhub-container, .pubhub-doc-container {
  @import "doc/base";
  @import "doc/common";
  @import "doc/variables";
  @import "doc/search";
  @import "doc/nav";
  @import "doc/tab";
  @import "doc/content";
  @import "doc/switcher";
  @import "doc/content_xmart";
  @import "doc/content_swagger";
  @import "doc/code-panel";
}

.navigate {
  top: 0;
}
dwc-header ~ div .navigate {
  top: 57px;
  @media (min-width: 992px) {
    top: 0;
  }
}

.pubhub-site-container {
  @import "site/index";
}

#pubhub-widgets, .pubhub-widgets {
  @import "bootstrap";
  @import "boostratp_extension/index";
  @import "common/widget";
  @import "widgets";

  .widget-loading {
    opacity: 0;
  }
  .widget-loading img {
    opacity: 0;
  }
}

//vendor
// @import "lib/aos/aos.css";
@import "../../../node_modules/selectize/dist/css/selectize.default.css";
@import "common/plugin";

// 404
#pubhub-widgets .dn-404, .pubhub-widgets .dn-404 {
  background: url(./img/404.png);
  background-size: 943px;
  height: 80%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px;
  min-height: 800px;
  @media screen and (max-width: 943px) {
    background-size:contain;
    min-height: 400px
  }

  a {
    border-radius: 4px;
    background-color: #00ace1;
    padding: 15px 75px;
    font-family: CiscoSans;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 562px;
    top: 443px;
    @media screen and (max-width: 943px) {
      left: 50%;
      top: 300px;
      margin-left: -132px
    }
  }
}
