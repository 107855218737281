
.pubhub-community{
  padding: 40px 0 60px 0;

  .pubhub-community-action{
    display: block;
    padding-top: 10px;
    
    .btn{
      margin: 0 5px;      
    }
  }

  &-title{
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    line-height: 1.2;
    color: #4a4a4a;
    padding: 16px 0 28px 0;
  }

  .pubhub-slider{
    display: none;
    margin-bottom: 40px;
  }
  
  .pubhub-community-page{
    margin: 0 5px;

    &-frame{
      position: relative;
      background-color: #777;
    }

    &-img{
      width: 100%;
      padding-bottom: 52.2604%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    &-mask, &-title{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &-title{
      background: linear-gradient( 0deg, rgba(0, 0, 0, .9) 10%, rgba(0, 0, 0, 0) 30%);
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      transition: all ease-in-out .1s;

      h2{
        position: absolute;
        bottom: 0;
        margin: 0 0 20px 20px;
        color: #FFF;
        transition: all ease-in-out .1s;
      }
    }

    &-mask{
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover{
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.3);
        
        .pubhub-community-page-action{
          display: block;
        }
      }
    }

    &-action{
      display: none;
      background-color: #01a89e;
      font-family: "CiscoSansBold", sans-serif;
      color: #FFFFFF;
      border: none;
      padding: 12px 20px;
      border-radius: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-text{
      padding: 20px 22px;
      color: #4D4D4D;
      background: #fff;
      border: 1px solid #ddd;
    }

    &-description{
      font-size: 14px;
      line-height: 1.48;
      overflow: hidden;
      height: 60px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

@media (min-width: 576px){
  .pubhub-community{

    .pubhub-community-action {
      padding-top: 40px;
    }
  }
}

@media (min-width: 768px){
  .pubhub-community{

    .pubhub-slider{
      display: block;
    }
  }
}