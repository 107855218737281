$sample-code-item-border-color: #ddd;

.pubhub-sample-code-blocks {

  padding-bottom: 4.375rem;
  &-title {
    .pubhub-info-card-title {
      text-align: center;
      padding-top: 3.75rem;
      font-size: 2.375rem;
      line-height: 1.1;
      color: $gray-dark;
      font-weight: $font-weight-extra-light;
    }
  }
  .pubhub-sample-code-blocks-list {
    margin-top: 2.5rem;

    &-col {
      display: flex;
    }

    &-item {
      display: block;
      min-height: 13.75rem;
      padding: .625rem .625rem .625rem 0.9375rem;
      margin-bottom: 1.875rem;
      background-color: white;
      box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in;
      width: 100%;

      &:hover{
        box-shadow: 0 3px 7px 1px rgba(181, 181, 181, 0.78);
      }

      &-language {
        text-align: right;
        span{
          border-radius: 0.125rem;
          background-color: $blue;
          font-size: 0.75rem;
          padding: 0.125rem .6rem;
          color: white;
        }

      }

      .pubhub-info-card-link {
        margin-top: .625rem;
        font-size: 1.375rem;
        line-height: 1.3;
        color: $blue;
        display: inline-block;
        margin-bottom: .2rem;
      }

      &-author {
        line-height: 0.92*$line-height-base;
        color: $gray-dark;

        span {
          display: inline-block;
          margin-right: 3px;
          font-size: calculateRem(14px);
        }

        &-name {
          font-size: 0.875rem;
          color: $gray-dark;
        }

      }

      .pubhub-info-card-description {
        margin-top: 0.625rem;
        font-size: calculateRem(14px);
        line-height: $line-height-base*.92;
        color: $gray;
      }
    }
  }

  &-button {
    margin-top: .25rem;
    .col{
      text-align: right;
    }
  }


  .pubhub-info-card-link-btn{
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }


}
