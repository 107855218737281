// try toi use boostrap variable as much as you can
// tyry to not use height:100%
// calculate most of sizing px to rem
// basic rem is equal to 16px
//
.pubhub-sandbox-info-card {
  padding-top: 3rem;

  &-img {
    //text-align: center;

    img {
      width: 18.75rem;
      max-height: 11.875rem;
      box-shadow: 2px 2px 4px 0 rgba($black, .2);
      @media (max-width: map-get($grid-breakpoints, xl)) {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .row{
    //flex-wrap: nowrap; // for safari
  }

  &-detail {
    @media (max-width: map-get($grid-breakpoints, xl)) {
      margin-top: 1.5rem;
    }
    &-title{
      line-height: 1.1;
    }
  }

  .pubhub-info-card-subtitle {
    font-weight: $headings-font-weight;
    color: $gray-dark;
  }

  .pubhub-info-card-title {
    line-height: $line-height-base * 1.28;
  }

  .pubhub-info-card-title a {
    color: $blue;
    font-size: 1.625rem;
    &:hover {
      text-decoration: none;
    }

  }

  .pubhub-info-card-description {
    line-height: $line-height-base * 1.08;
    margin-bottom: 1.25rem;
    margin-top: 0.625rem;
    color: $gray-dark;

    ul{
      margin: .6rem 0 0 0 ;
      //padding-left: 1.25rem;
    }

    ul br{
      display: none;
    }

  }

  .pubhub-info-card-link-btn{
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }


}
