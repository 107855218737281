$pubhub-discussion-border-color: #dddddd;
.pubhub-discussion{
  border-radius: 0.25rem;
  background-color: white;
  border: solid 0.0625rem $pubhub-discussion-border-color;
  padding: 2rem;
  height: 100%;

  @media (max-width: map-get($grid-breakpoints, sm)){
    padding: 2rem 0rem 2rem 1rem;
  }

  &-title{
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: $line-height-base*0.587;
    text-align: left;
    color: $blue;
    margin: 0;

    &:after{
      content:' ';
      position:absolute;
      width:0;
      height:0;
      border: 0.375rem solid;
      border-color: transparent transparent transparent $blue;
      margin-left: calculateRem(10px)
    }

    &:hover:after{
      border-color: transparent transparent transparent $link-hover-color;
    }

  }

  &-list{
    margin-top: 2.1875rem;

    &-item{
      margin-bottom: 1.25rem;

      &-img{
        padding-left: 0;
        padding-right: 0;
      }

      &-title{
        @media (min-width: map-get($grid-breakpoints, sm)+1px){
          max-width: calc(100% - 60px);
        }
      }

      .pubhub-info-card-image{

        img{
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 0.5rem;
          background-color: #d8d8d8;
        }
      }
      .pubhub-info-card-title{

        &-link{
          display: inline-block;
          font-size: 1.125rem;
          line-height: $line-height-base*0.8;
          color: $blue;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (min-width: map-get($grid-breakpoints, sm)+1px){
            white-space: nowrap;
          }

          &:hover{
            text-decoration: none;
          }

        }
      }
      &-subtitle{
        font-size: 0.875rem;
        line-height: $line-height-base*0.8;
        color: $gray-dark;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: map-get($grid-breakpoints, sm)+1px){
          white-space: nowrap;
        }

        &-link{
          color: $blue;
        }
      }

    }

  }
}
