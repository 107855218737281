dwc-meraki-subheader {
  position: static;
}
@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

#pubhub-container .doc-breadcrumb {
  padding: 18px 0;
  height: 62px;
  border-bottom: 1px solid #dfdfdf;
  background-color: #FFF;
  z-index: 96;
  display: none;
  position: sticky;
  top: 0;
  font-size: 14px;
  font-weight: 300;
  color: #333;
  @media (min-width: 992px) {
    display: block;
    top: 0;
  }  
  &__container {
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  &__links {
    list-style: none;
    display: flex;
  }

  &__link {
    color: #0076D5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__arrow {
    display: inline-block;
    vertical-align: sub;
    width: 16px;
    height: 16px;
    margin: 0 8px;
    background-image: url(./arrow.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .col-label {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;

    .doc-label {
      border-radius: 4px;
      padding: 4px 10px 4px 10px;
      font-size: 12px;
      color: #fff;

      &.label-warn {
        background-color: #dc3545;
      }
      &.label-success {
        background-color: #198754;
      }
      &.label-primary {
        color: #fff;
        background-color: #02bceb;
      }
    }
  }


  &__search {
    width: 200px;
    height: 32px;
    background: white;
    border-radius: 32px;
    position: relative;
    display: flex;
    padding: 0 8px;
    align-items: center;
  }
  &__search-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(search.svg);
    background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
  &__search-text {
    color: #495D79;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    flex: 1;
    cursor: text;
  }
  &__search-tag {
    width: 36px;
    height: 14px;
    text-align: center;
    border-radius: 999px;
    background: #E5EEF7;
    color: #0076D5;
    font: normal 400 10px / 14px CiscoSans;
  }
  .gradual-border {
    width: 200px;
    height: 32px;
    background: white;
    border-radius: 32px;
    position: relative;
    top: 1px;
  }
  
  .gradual-border::before,
  .gradual-border::after {
    content: "";
    position: absolute;
    inset: -1px;
    z-index: -1;
    background: conic-gradient(
      from var(--gradient-angle),
      #c299ff,
      #80def5,
      #c299ff
    );
    border-radius: inherit;
    animation: rotation 3.5s linear infinite;
  }
}
