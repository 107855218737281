.pubhub-smartsheet-form-element {
  margin: 18px 0;
}

.pubhub-smartsheet-label {
  //@include make-col-ready();

  @media (max-width: 32em) {
    @include make-col(12);
  }
  @media (min-width: 32.1em) {
    @include make-col(12);
  }
}


.pubhub-smartsheet-element {
  //@include make-col-ready();

  @media (max-width: 32em) {
    @include make-col(12);
  }
  @media (min-width: 32.1em) {
    @include make-col(12);
  }
}

.pubhub-smartsheet-form-required {
  color: red;
}

.pubhub-smartsheet-form-section-title {
  @include make-col(12);
}

.pubhub-smartsheet-textarea {

  textarea {
    resize: none;
  }
}

.pubhub-smartsheet-markdown {

  textarea {
    resize: none;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
  }

  .nav-link{
    cursor: pointer;
    color: #666;
  }

  .preview{
    border: solid 1px #ccc;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    min-height: 30px;
    padding: 12px;

    img{
      max-width: 100%;
    }

  }
  .nav-tabs{
    border-radius: 4px 4px 0 0 ;
    background-color: #f1f8ff;
    border: #c0d3eb 1px solid;
    padding: 5px 5px 0px 5px;
    position: relative;
  }
  .upload{
    position: absolute;
    right: 15px;
    top:15px;
    cursor: pointer;

    i{
      color: #049fd9;
      font-size: 22px;
      vertical-align: middle;
      margin-right: 4px;
    }
    span{
      font-size: 12px;
      color: #777;
    }

    &:hover{
        span{
          color: #049fd9;
        }
    }
  }

  .md_link{
    position: absolute;
    right: 150px;
    top: 17px;
    font-size: 12px;
    color: #777;
    border-bottom: 1px #ccc dotted;



  }

}

.pubhub-smartsheet-checkbox {
  input {
    display: inline-block;
    width: auto;
    margin-right: 6px;
    vertical-align: baseline;
  }
}

.inner-addon {
  position: relative;
}

.inner-addon .fas,
.inner-addon .fal {
  position: absolute;
  padding: 10px;
}

.left-addon .fas, .left-addon .fal {
  left: 0px;
}

.right-addon .fas, .right-addon .fal {
  right: 0px;
}

.left-addon input ,
.left-addon span.text {
  padding-left: 30px;
  word-break: break-all;
}

.right-addon input,
.right-addon span.text {
  padding-right: 30px;
  word-break: break-all;
}

.text-muted{
  margin-bottom: 10px;

  p{
    color: #636c72 !important;
    font-size: 100%;
    text-align: left;
  }

}

.pubhub-smartsheet-calendar {

  position: relative;

  .fas {
    color: #049fd9;
    cursor: pointer;
  }

  .pubhub-smartsheet-element {
    max-width: 380px;
  }


  &-container {
    position: absolute;
    z-index: 100;
  }

  .react-calendar {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0 none;
    border-radius: 0 0 0.25rem 0.25rem;
  }

}


.pubhub-smartsheet-uploader {
  .fas, .fal {
    color: #049fd9;
    margin-right: 6px;
  }

  button{
    max-width: 100%;
    overflow: hidden;
    @media (max-width: 32em) {
      width: 100%;
      text-align: left;
    }

    span{
      word-break: break-all;
    }
  }


  &-list-image {
    justify-content:flex-start;
    flex-direction: row;
  }

  &-list-item-image {
    list-style: none;
    position: relative;
    flex-wrap: nowrap;
    margin: 10px;
    img{
      max-width: 160px;
      max-height: 160px;
    }

    .fal{
      position: absolute;
      font-size: 30px;
      bottom: 5px;
      margin-left: -35px;
      display: none;
    }


    &:hover {
      cursor: pointer;
      img{
        opacity: 0.6;
      }

      .fal{
        display: inline-block;
      }
    }

  }

}

.pubhub-smartsheet-form-divider{
  //@include make-col-ready();
  //@include make-col(12);

  border-bottom: solid 1px #ccc;
  margin-bottom: 16px;
}

.pubhub-smartsheet-form-action{
  margin-bottom: 30px;
}
.pubhub-smartsheet-form-success{
  p{
    text-align: left;
    color: #333;
  }
}
