& {
  font-family: "CiscoSans", "Arial", sans-serif;;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

& a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

& .pubhub-info-card-description {
  word-break: break-word;
}

.pubhub-section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &-title {
    padding-bottom: 1.25rem !important;
    font-size: 2.375rem !important;
    color: #292b2c !important;
    font-weight: 200 !important;
    margin: 20px 0 !important;
    text-align: center !important;
  }

  &-description {
    font-size: 1.125rem !important;
    line-height: 1.39 !important;
    text-align: center !important;
    color: #292b2c !important;
    padding-bottom: 3rem !important;
  }

}
